<!-- START STICKY HEADER -->
<div class="sticky-header">
  <header>
    <div class="container">
      <div class="row middle-xs">
        <div class="col-sm-2 header-logo">
          <div>
            <img src="/svg/logo.svg" alt="logo" />
            <span>{{ texts.productTitle }}</span>
          </div>
        </div>
        <div class="col-sm-10 text-right"> 
          <p>{{ texts.headerTitle }}</p>
        </div>
      </div>
    </div>
  </header>
</div>
<!-- END  STICKY HEADER -->

<!-- START TITLE SECTION -->
<section class="title-wrapper text-center">
  <div class="container">
    <h1 class="title">{{ texts.mainTitle }}</h1>
    <p class="subtitle">{{ texts.subTitle }}</p>
  </div>
</section>
<!-- END TITLE SECTION -->

<!-- START MAIN SECTION -->
<section class="hero">
  <div class="container">
    <div class="row column-list between-xs">

      <!-- start left ads block -->
      <div class="col column-ads mobile-hidden">
        <div class="ad-item">
          <ng-adsense
            [adClient]="'ca-pub-1981147026628502'"
            [display]="'block'"
            [adFormat]="'auto'"
            [fullWidthResponsive]="true"
            [width]="250"
            [height]="500"
            [adSlot]="6553791368"
          ></ng-adsense>
        </div>
        <div class="ad-item">
          <ng-adsense
            [adClient]="'ca-pub-1981147026628502'"
            [display]="'block'"
            [adFormat]="'auto'"
            [fullWidthResponsive]="true"
            [width]="250"
            [height]="500"
            [adSlot]="7943142077"
          ></ng-adsense>
        </div>
      </div>
      <!-- end left ads block -->

      <div class="col column-center">
        <!-- start converter section -->
        <div class="pronto text-center" appDnd (fileDropped)="fileDroppedHandler($event)">
          <!-- start upload screen -->
          <div [ngClass]="{'pronto__upload-file': true, 'hide': progress !== 'start'}">
            <div class="pronto-upload">
              <input type="file" #fileDropRef id="fileDropRef" multiple class="hide" />
              <div class="drop-zone">
                <img src="svg/zip.svg" alt="pdf" />
                <p>{{ texts.mainGuidline }}</p>
              </div>
              <div class="separate-line">
                <span>{{ texts.mainOr }}</span>
              </div>
              <div>
                <button class="btn" (click)="fileBrowseHandler()">
                  <span>{{ texts.mainButton }}</span>
                  <img src="svg/document.svg" alt="document">
                </button>
              </div>
              <div class="row secured-row">
                <div class="clickableArea" (click)="showSecurityPopup()">
                  <img alt="lock" src="svg/lock.svg" />
                  <span>Your files are secure</span>
                  <div [ngClass]="{'secured-popup': true, 'hide': popupStatus == false}">
                    Your files are only stored on our servers for 24 hours, after which they are permanently destroyed
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end upload screen -->
          <!--  start files screen -->
          <div [ngClass]="{'pronto__extract': true, 'hide': progress !== 'files'}">
            <div class="box" *ngIf="files.length > 0">
              <div class="files-wrapper">
                <div class="file row" *ngFor="let file of files; let i = index">
                  <span class="file-counter">{{i+1}}. </span>
                  <span class="file-name">{{file.name}}</span>
                  <div class="file-garbage" alt="garbage can" (click)="removeFile(file.name)"></div>
                </div>
              </div>
            </div>

            <div class="bttn-wrapper column">
              <div class="btn-add" (click)="fileBrowseHandler()">
                <img src="svg/plus.svg" alt="plus" />
                <span>{{ texts.formatMoreButton }}</span>
              </div>
              <div class="row">
                <div class="format-dropdown" *ngIf="formatsList.length > 0">
                  <select class="btn test-select" #selectFormat (change)="selectedFormat = selectFormat.value">
                    <option hidden>{{texts.formatDefaultOption}}</option>
                    <option class="test" *ngFor="let format of formatsList; let i = index" value="{{format}}"><div>{{ format.toUpperCase() }}</div></option>
                  </select>
                </div>     
                <button class="btn disabled" *ngIf="formatsList.length > 0" (click)="upload()">{{ texts.formatCompressButton }}</button>
                <button class="btn-transparent" data="Start over" (click)="reboot('start')" *ngIf="formatsList.length == 0"></button>
                <button class="btn" (click)="upload()" *ngIf="formatsList.length == 0">{{texts.formatExtractButton}}</button>
              </div>
            </div>
          </div>
          <!-- end files screen -->

          <!-- start convert screen -->
          <div [ngClass]="{'pronto__converting p-2': true, 'hide': progress !== 'converting'}">
            <p class="title">{{ texts.convertingTitle }}...</p>
            <div class="m-auto">
              <img src="images/spinner.gif" alt="loader" />
            </div>
            <p><a (click)="reboot('cancel')" style="cursor: pointer;">{{ texts.cancelTitle }}</a></p>
          </div>
          <!-- end convert screen -->

          <!-- start completed screen -->
          <div [ngClass]="{'pronto__done': true, 'hide': progress !== 'completed'}">
            <img src="images/ok1.gif" alt="done" />
            <h3 class="title">{{ texts.completedGuidline1 }}<br />
              {{ texts.completedGuidline2 }}</h3>
              <p class="pronto-done-text">{{ texts.completedGuidline3 }}, <a (click)="upload()" class="try-again">{{ texts.shortTryAgain }}.</a></p>
            <button class="btn" (click)="reboot('start')" >{{ texts.startOver }}</button>
          </div>
          <!-- end completed screen -->

          <!-- start cancel screen -->
          <div [ngClass]="{'pronto__cancel': true, 'hide': progress !== 'cancel'}">
            <img src="svg/warning.svg" alt="warning">
            <p>{{ texts.cancelGuidline1 }}<br />{{ texts.cancelGuidline2 }}</p>
            <div class="button-wrapper">
              <button class="btn" (click)="reboot('start')">{{ texts.yesTitle }}</button>
              <button class="btn-transparent" data="No" (click)="partialReboot('converting')"></button>
            </div>
          </div>
          <!-- end cancel screen -->

          <!-- start duplicate screen -->
          <div [ngClass]="{'pronto__cancel': true, 'hide': progress !== 'duplicated'}">
            <img src="svg/warning.svg" alt="warning">
            <p>{{ texts.duplicatedGuidline1 }} <br /> {{ texts.duplicatedGuidline2 }}.</p>
            <div>
              <button class="btn" (click)="partialReboot('files')">
                <span>{{ texts.okTitle }}</span>
              </button>
            </div>
          </div>
          <!-- end duplicate screen -->

          <!-- start error screen -->
          <div [ngClass]="{'pronto__cancel': true, 'hide': progress !== 'error'}">
            <img src="svg/warning.svg" alt="warning">
            <p>{{texts.errorTitle}} <br /> {{texts.longtTryAgain}}.</p>
            <div>
              <button class="btn" (click)="partialReboot('start')">
                <span>{{texts.okTitle}}</span>
              </button>
            </div>
          </div>
          <!-- end error screen -->
        </div>
        <!-- end converter section -->

        <!-- start first content block -->
        <div class="content-first">
          <h2 >
            Why Choose ZipRar Archiver?
          </h2>
          <div class="row">
            <div class="inner-block col-sm-6">
              <img alt="protect icon" src="svg/shield.svg" />
              <h4>100% Protected Files!</h4>
              <p>
                If you're worried about your safety, you'll be relieved. Your files are completely secure when you use ZipRar Archiver.
                <br />Please take a look at our privacy policy for more details.
              </p>
            </div>
            <div class="inner-block col-sm-6">
              <img alt="compress icon" src="svg/crown.svg" />
              <h4>The Best Free ZIP Tool</h4>
              <p>
                Our online file tool is more than just a Zip file extract tool, no matter what types of files you need to extract 
                or compress. It's your one-stop tool for all files extract and compress needs.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="inner-block col-sm-6">
              <img alt="zip icon" src="svg/zipfileformat.svg" />
              <h4>Compress Files Online</h4>
              <p>
                ZipRar is accomplishes exactly what its name implies: 
                it works from any desktop device, Simply utilize your 
                web browser with a FREE easy to use tool.
              </p>
            </div>
            <div class="inner-block col-sm-6">
              <img alt="zip icon" src="svg/gear.svg" />
              <h4>Easily Extract Files</h4>
              <p>
                You can select the converting technique after dragging & dropping your file into ZipRar or uploading 
                it from your own computer.
              </p>
            </div>
          </div>
        </div>
        <!-- end first content block -->

        <!-- start second content block -->
        <div class="content-second">
          <h2>
            More Info About ZipRar Archiver Tool
          </h2>
          <div class="row">
            <div class="inner-block col-sm-4">
              <h4>Over a 20 Million Users</h4>
              <p>
                Yes, it is correct! Since we launched our ZipRar tool in 2015, we've helped over 20 million
                people make Zip files simple.
              </p>
            </div>
            <div class="inner-block col-sm-4">
              <h4>Used in More Than 150 Countries</h4>
              <p>
                Zip files, as well as ZipRar Archiver tool, are used in every country on the planet. 
                Even in Antarctica, we're exploited!
              </p>
            </div>
            <div class="inner-block col-sm-4">
              <h4>There Is No Download Limit!</h4>
              <p>
                With our free software you can extract, compress and download as many files as you want, there 
                is no limit to the amount of files you extract.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="inner-block col-sm-4">
              <h4>Highly Rated</h4>
              <p>
                The most popular Zip tool website in the US, and consistently among the top 100 most 
                popular Zip tool websites worldwide.
              </p>
            </div>
            <div class="inner-block col-sm-4">
              <h4>Maintenance & Improvement</h4>
              <p>
                Our team has ongoing development and maintenance tasks, so you are part of a product that always improves itself!
              </p>
            </div>
            <div class="inner-block col-sm-4">
              <h4>Desktop / Tablet at Anytime!</h4>
              <p>
                We're all over the place, and so are Zip files. On the web, desktop, and tablet, we keep those simple chores simple.
              </p>
            </div>
          </div>
        </div>
        <!-- end second content block -->
      </div>

      <!-- start right ads block -->
      <div class="col column-ads mobile-hidden">
        <div class="ad-item">
          <ng-adsense
            [adClient]="'ca-pub-1981147026628502'"
            [display]="'block'"
            [adFormat]="'auto'"
            [fullWidthResponsive]="true"
            [width]="250"
            [height]="500"
            [adSlot]="4873878183"
          ></ng-adsense>
        </div>
        <div class="ad-item">
          <ng-adsense
            [adClient]="'ca-pub-1981147026628502'"
            [display]="'block'"
            [adFormat]="'auto'"
            [fullWidthResponsive]="true"
            [width]="250"
            [height]="500"
            [adSlot]="4682306492"
          ></ng-adsense>
        </div>
      </div>
      <!-- end right ads block -->
    </div>
  </div>
</section>
<!-- END MAIN SECTION -->

<section class="footer">
  <div class="footer-links">
    <a href="https://www.ziprararchiver.com/privacy">Privacy</a>
    <a href="https://www.ziprararchiver.com/eula">EULA</a>
    <a href="https://www.ziprararchiver.com/contact">Contact</a>
    <a href="https://www.ziprararchiver.com/blog/">Blog</a>
  </div>
  <div class="your-files-are-secure">© ZipRar Archiver, 2015–2022</div>
</section>