import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators'; 
import { HttpErrorResponse, HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {
  url = 'https://srvc.ziprararchiver.com/'
  routes = {
    extract: 'api/extract/',
    compress: 'api/compress/',
  };

  constructor(private httpClient: HttpClient) {}

  doCompress(files: File[], format: string) {
    const response = {error: null, subscribtion: null, rejected: []};
    response.subscribtion = this.compressFiles(files, format)
    return response
  }

  doExtract(files: File[]) {
    const response = {error: null, subscribtion: null, rejected: []};
    response.subscribtion = this.extractFiles(files)
    return response
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string;

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //return throwError(errorMessage);
    return errorMessage
  }

  private compressFiles(files: any, format: string) {
    let formData: FormData = new FormData();
    
    Object.values(files).forEach((value:File, index)=> {
      formData.append(`file${index+1}`, value, value.name);
    });

    return this.httpClient
    .post<any>(this.url + this.routes.compress + format + '/' + files.length, formData)
    .pipe(catchError(this.handleError));
  }

  private extractFiles(files: any) {
    let formData: FormData = new FormData();
    
    Object.values(files).forEach((value:File, index)=> {
      formData.append(`file${index+1}`, value, value.name);
    });

    return this.httpClient
    .post<any>(this.url + this.routes.extract, formData)
    .pipe(catchError(this.handleError));
  }
}