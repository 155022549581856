import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
    subscriptions: Array<Subscription> = [];

    pushSubscription(value: Subscription) {
      this.subscriptions.push(value);
    }
  
    release() {
      Object.values(this.subscriptions).forEach((s:Subscription, index)=> {
        s.unsubscribe
      })
    }

    // isThisAFile(maybeFile) {
    //   return new Promise(function (resolve, reject) {
    //     if (maybeFile.type !== '') {
    //       return resolve(maybeFile)
    //     }
    //     const reader = new FileReader()
    //     reader.onloadend = () => {
    //       if (reader.error && reader.error.name === 'NotFoundError') {
    //         return reject(maybeFile)
    //       }
    //       resolve(maybeFile)
    //     }
    //     reader.readAsBinaryString(maybeFile)
    //   })
    // }
}