import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DndDirective } from './dnd.directive';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ConverterService } from './converter.service';
import { UtilsService } from './utils.service';
import { HttpClientModule } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [
    AppComponent,
    DndDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,AppRoutingModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-1981147026628502'
    }),
    CookieModule.forRoot(),
  ],
  providers: [ConverterService, UtilsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
