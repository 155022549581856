import { Component, ViewChild, ElementRef } from '@angular/core';
import { ConverterService } from './converter.service';
import { UtilsService } from './utils.service';
import { CookieService } from 'ngx-cookie';
import { DataLayerService } from './data-layer.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  texts = {
    productTitle: 'ZipRar',
    headerTitle: 'Compress and extract files with ZipRar Archiver',
    mainTitle: 'Compress Files Online',
    subTitle: 'An easy file compression software',
    mainGuidline: 'Drag & Drop File Here',
    mainOr: "or",
    mainButton: "Browse files",
    formatDefaultOption: "Format",
    formatCompressButton: "Compress",
    convertingTitle: "Converting",
    cancelTitle: "Cancel",
    formatMoreButton: "Add more files",
    cancelGuidline1: "Are you sure you want to",
    cancelGuidline2: "cancel conversion?",
    yesTitle: "Yes",
    completedGuidline1: "Your file has been",
    completedGuidline2: "downloaded",
    completedGuidline3: "if you do not see the download ",
    shortTryAgain: "try again",
    longtTryAgain: "Please, try again",
    startOver: "Start over",
    duplicatedGuidline1: "Upload failed. One or more of the files",
    duplicatedGuidline2: "are duplicated",
    okTitle: "OK",
    formatExtractButton: "Extract",
    errorTitle: "Error occurred",
  }
  
  private _progress:string = 'start'
  private progress_arr = ['start', 'files', 'converting', 'cancel', 'error', 'completed', 'duplicated']

  formats = ['rar', 'zip'];
  formatsList: any[] = [];
  private _selectedFormat: string;
  files: any[] = [];
  duplicatedFiles: any[] = [];
  temporaryFiles: any[] = [];
  lp_id = null;
  popupStatus: boolean = false;

  @ViewChild("fileDropRef", {static: false}) fileDropRef: ElementRef
  
  constructor(
    private converterService: ConverterService,
    private utilsService: UtilsService,
    private cookieService: CookieService,
    private dataLayerService: DataLayerService,
    private _router:Router
  ) {
    this._router.events.subscribe(event=> { // subscribe to router events
      if (event instanceof NavigationEnd) //if our event is of our interest
      {
          this.dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
      }
    });
  }

  set progress(val: string) {
    this._progress = val
  }

  get progress(): string {
    return this._progress;
  }

  getCookie(key: string){
    return this.cookieService.get(key);
  }

  fileDroppedHandler(files) {
    this.dataLayerService.logEvent('ga_event','drop','browse', '');
    this.validateFiles(files, this.chooseFormat)
  }

  showSecurityPopup() {
    this.popupStatus = !this.popupStatus;
  }

  fileBrowseHandler() {
    this.dataLayerService.logEvent('ga_event','click','browse', '');
    const fileDropRef = this.fileDropRef.nativeElement;
    fileDropRef.click();

    fileDropRef.onchange = () => {
      this.validateFiles(fileDropRef.files, this.chooseFormat)
    }
  }

  validateFiles(files:File[], callback) {
    for (var i = 0; i < files.length; i++) {
      // this.utilsService.isThisAFile(files[i])
        // .then((validFile:File) => {
          this.checkDuplicated(files[i])
          //last itteration
          if(files[i].name == files[files.length - 1].name) {
            this.afterValidation(callback)
          }
        // })
        // .catch(invalidFile => {
          // console.log(`Error: folder not allowed`)
          //last itteration
          // if(invalidFile.name == files[files.length - 1].name) {
          //   this.afterValidation(callback)
          // }
        // })
    }
  }

  afterValidation(f) {
    if(this.duplicatedFiles.length > 0) {
      this.partialReboot('duplicated')
    } else {
      this.files.push(...this.temporaryFiles)
      this.temporaryFiles = []
      f.call(this)
    }
  }

  checkDuplicated(file) {
    const isDuplicated = this.files.some(function(el) {
      return el.name === file.name;
    });
    if(isDuplicated) {
      this.duplicatedFiles.push(file)
    } else {
      this.temporaryFiles.push(file)
    }
  }

  chooseFormat() {
    if(this.duplicatedFiles.length > 0) {
      this.progress = 'duplicated'
      return
    } else if(this.files.length === 0) {
      this.progress = 'start'
    } else {
      this.progress = 'files'
    }

    if(this.files.length === 1) {
      const ext = this.files[0].name.split('.').pop().toLowerCase();
      if( ! this.formats.includes(ext)) {
        this.formatsList = this.formats;
      }
    } else {
      this.formatsList = this.formats;
    }
  }

  set selectedFormat(val: string) {
    this._selectedFormat = val;
    if(val != this.texts.formatDefaultOption) {
      var elems = document.querySelectorAll(".pronto__extract .disabled");

      for (var i = 0; i < elems.length; i++) {
          elems[i].classList.remove('disabled');
      }
    }
  }

  get selectedFormat(): string {
    return this._selectedFormat;
  }

  private downloading(file) {
    console.log("downloading")
    const file_protcol = file["file"]["url"].includes("https:") || file["file"]["url"].includes("http:") ? "" : `https:`;
    if (window.self !== window.top) {

      window.parent.postMessage(
        {
          event_id: 'conversionComplete',
          params: {
            url: file_protcol + file["file"]["url"],
            name: file["file"]["filename"]
          }
        },
        "*"
      );
    }
    else {
      window.location = file["file"]["url"];
    }
    // this.files = []
    this.progress = 'completed'
  }

  partialReboot(progress:string) {
    if(progress && this.progress_arr.includes(progress)) {
      this.progress = progress
    }
    this.fileDropRef.nativeElement.value = null
    this.temporaryFiles = []
    this.duplicatedFiles = []
  }

  reboot(progress:string) {
    if(progress && this.progress_arr.includes(progress)) {
      this.progress = progress
    }

    this.utilsService.release()

    this.files = [];
    this.temporaryFiles = []
    this.duplicatedFiles = []
    this.formatsList = [];
    this.selectedFormat = null;
    this.fileDropRef.nativeElement.value = null;

    var elems = document.querySelectorAll(".format .disabled");
    for (var i = 0; i < elems.length; i++) {
      elems[i].classList.add('disabled');
    }
  }

  removeFile(filename: string) {
    for (const [key, value] of Object.entries(this.files)) {
      if(value.name == filename) {
        const index = parseInt(key)
        if(this.files.length == 1) {
          this.reboot('start');
        } else {
          this.files.splice(index, 1);
        }
      }
    }
  }

  upload() {
    this.progress = 'converting';
    let response;
    if(this.formatsList.length > 0) {
      this.dataLayerService.logEvent('ga_event','compress','compress', '');
      response = this.converterService.doCompress(this.files, this.selectedFormat);
    } else {
      this.dataLayerService.logEvent('ga_event','extract','extract', '');
      response = this.converterService.doExtract(this.files);
    }

    if(response.error) {
      this.reboot('error')
    } else {
      const s = response.subscribtion.subscribe(result => {
        console.log(result)
        if(this.progress == 'converting' || this.progress == 'cancel') {
          if (result['success'] == true) {
            console.log(result)
            this.downloading(result)
          } else {
            this.reboot('error-occurred')
          }
        }
      })
      this.utilsService.pushSubscription(s)
    }
  }

}
